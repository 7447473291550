import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigFacade } from '@funceme/core-app';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-calendario-legenda',
  templateUrl: './calendario-legenda.component.html',
  styleUrls: ['./calendario-legenda.component.css']
})
export class CalendarioLegendaComponent implements OnInit, OnDestroy {

  public tipo:string = "Diária"
  public legenda = [];

  private subscription: Subscription;

  private legenda_diaria = [
    {cor:'roxo',texto:'Acima de 50.1'},
    {cor:'azul-claro',texto:'De 25.1 a 50.0'},
    {cor:'verde',texto:'De 15.1 a 25.0'},
    {cor:'amarelo',texto:'De 5.1 a 15.0'},
    {cor:'vermelho',texto:'De 0.1 a 5.0'},
    {cor:'branco',texto:'Sem Chuva (0 mm)'},
    {cor:'cinza',texto:'Sem Informação'}
  ]

  private legenda_mensal = [
    {cor:'roxo',texto:'Acima de 400.0'},
    {cor:'azul-escuro-anual',texto:'De 300.1 a 400.0'},
    {cor:'azul-claro',texto:'De 200.1 a 300.0'},
    {cor:'verde',texto:'De 100.1 a 200.0'},
    {cor:'amarelo',texto:'De 50.1 a 100.0'},
    {cor:'vermelho',texto:'De 0.1 a 50.0'},
    {cor:'branco',texto:'Sem Chuva (0 mm)'},
    {cor:'cinza',texto:'Sem Informação'}
  ]

  private legenda_anual = [
    {cor:'roxo',texto:'Acima de 1600.0'},
    {cor:'azul-escuro-anual',texto:'De 1300.1 a 1600.0'},
    {cor:'azul-claro',texto:'De 1000.1 a 1300.0'},
    {cor:'verde',texto:'De 700.1 a 1000.0'},
    {cor:'amarelo',texto:'De 400.1 a 700.0'},
    {cor:'vermelho',texto:'De 200.1 a 400.0'},
    {cor:'branco',texto:'De 0 a 200.0'},
    {cor:'cinza',texto:'Sem Informação'}
  ]

  constructor(
    private configFacade: ConfigFacade,
  ) {
    this.subscription = this.configFacade.current$.subscribe(value => {

      switch (value.periodo) {
        case 'diario':
          this.legenda = this.legenda_diaria
          break;
        case 'dia':
          this.legenda = this.legenda_diaria
          break;
        case 'mensal':
          this.legenda = this.legenda_mensal
          break;
        case 'mes':
          this.legenda = this.legenda_mensal
          break;
        case 'bimestral':
          if (value.produto == 'municipios')
          {
            this.legenda = this.legenda_anual
          }
          else
          {
            this.legenda = this.legenda_mensal
          }
        case 'bimestre':
          if (value.produto == 'municipios')
          {
            this.legenda = this.legenda_anual
          }
          else
          {
            this.legenda = this.legenda_mensal
          }
          break;

        default:
          this.legenda = this.legenda_anual
          break;
      }
    })
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
